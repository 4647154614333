ptz-radio {
  display: flex;
  align-items: center;
}

.ptz-radio {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
  border-radius: $petz-rounded-max;
  position: relative;
  background: transparent;
  border-width: $petz-border-md;
  border-style: solid;

  &:not(.ptz-radio:checked) {
    opacity: $petz-opacity-lg;

    &[disabled] {
      opacity: $petz-opacity-xs;
    }
  }

  &[disabled] {
    opacity: $petz-opacity-xs;
  }

  &-light {
    border-color: $petz-color-neutral-dark;

    &:checked {
      border-color: $petz-color-primary-brand;

      &::after {
        background-color: $petz-color-primary-brand;
      }
    }
  }

  &-dark,
  &-color {
    border-color: $petz-color-neutral-white;

    &-disabled {
      border-color: $petz-color-neutral-white;
    }
  }

  &-dark {
    &:checked {
      border-color: $petz-color-neutral-white;

      &::after {
        background-color: $petz-color-neutral-white;
      }
    }
  }

  &-color {
    &:checked {
      border-color: $petz-color-secondary-brand;

      &::after {
        background-color: $petz-color-secondary-brand;
      }
    }
  }

  &-light,
  &-dark,
  &-color {
    &-error {
      border-color: $petz-color-sup-red-mid;

      &:checked {
        border-color: $petz-color-sup-red-mid;

        &::after {
          background-color: $petz-color-sup-red-mid;
        }
      }
    }
  }

  &:hover {
    &::before {
      content: " ";
      border-radius: $petz-rounded-max;
      width: 150%;
      height: 150%;
      background-color: rgba($petz-color-neutral-dark, 0.09);
      box-sizing: border-box;
      display: block;
      margin: 0;
      position: absolute;
      left: -25%;
      top: -25%;
    }

    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;

      &::before {
        opacity: none;
      }
    }
  }

  &:checked {
    &::after {
      content: " ";
      width: $petz-spacing-sm;
      height: $petz-spacing-sm;
      border-radius: $petz-rounded-max;
      display: block;
      margin: 0;
      position: absolute;
    }
  }

  &-lg {
    height: $petz-spacing-lg;
    width: $petz-spacing-lg;

    &:checked {
      &::after {
        width: $petz-spacing-md;
        height: $petz-spacing-md;
        left: 2px;
        top: 2px;
      }
    }
  }

  &-md {
    height: $petz-spacing-md;
    width: $petz-spacing-md;

    &:checked {
      &::after {
        width: $petz-spacing-sm;
        height: $petz-spacing-sm;
        left: 2px;
        top: 2px;
      }
    }
  }

  &-sm {
    height: 20px;
    width: 20px;

    &:checked {
      &::after {
        width: 14px;
        height: 14px;
        left: 1px;
        top: 1px;
      }
    }
  }
}

srs-radio {
  display: flex;
  align-items: center;
}

.srs-radio {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
  border-radius: $seres-rounded-max;
  position: relative;
  background: transparent;
  border-width: $seres-border-md;
  border-style: solid;

  &:not(.srs-radio:checked) {
    opacity: $seres-opacity-lg;

    &[disabled] {
      opacity: $seres-opacity-xs;
    }
  }

  &[disabled] {
    opacity: $seres-opacity-xs;
  }

  &-light {
    border-color: $seres-color-neutral-dark;

    &:checked {
      border-color: $seres-color-neutral-black;

      &::after {
        background-color: $seres-color-neutral-black;
      }
    }
  }

  &-dark,
  &-color {
    border-color: $seres-color-neutral-white;

    &-disabled {
      border-color: $seres-color-neutral-white;
    }
  }

  &-dark {
    &:checked {
      border-color: $seres-color-neutral-white;

      &::after {
        background-color: $seres-color-neutral-white;
      }
    }
  }

  &-color {
    &:checked {
      border-color: $seres-color-secondary-brand;

      &::after {
        background-color: $seres-color-secondary-brand;
      }
    }
  }

  &-light,
  &-dark,
  &-color {
    &-error {
      border-color: $seres-color-sup-red-mid;

      &:checked {
        border-color: $seres-color-sup-red-mid;

        &::after {
          background-color: $seres-color-sup-red-mid;
        }
      }
    }
  }

  &:hover {
    &::before {
      content: " ";
      border-radius: $seres-rounded-max;
      width: 150%;
      height: 150%;
      background-color: rgba($seres-color-neutral-dark, 0.09);
      box-sizing: border-box;
      display: block;
      margin: 0;
      position: absolute;
      left: -25%;
      top: -25%;
    }

    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;

      &::before {
        opacity: none;
      }
    }
  }

  &:checked {
    &::after {
      content: " ";
      width: $seres-spacing-sm;
      height: $seres-spacing-sm;
      border-radius: $seres-rounded-max;
      display: block;
      margin: 0;
      position: absolute;
    }
  }

  &-lg {
    height: $seres-spacing-lg;
    width: $seres-spacing-lg;

    &:checked {
      &::after {
        width: $seres-spacing-md;
        height: $seres-spacing-md;
        left: 2px;
        top: 2px;
      }
    }
  }

  &-md {
    height: $seres-spacing-md;
    width: $seres-spacing-md;

    &:checked {
      &::after {
        width: $seres-spacing-sm;
        height: $seres-spacing-sm;
        left: 2px;
        top: 2px;
      }
    }
  }

  &-sm {
    height: 20px;
    width: 20px;

    &:checked {
      &::after {
        width: 14px;
        height: 14px;
        left: 1px;
        top: 1px;
      }
    }
  }
}