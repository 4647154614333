import { Component, Event, EventEmitter, h, Host, Listen, Prop } from '@stencil/core';
import { valueToLowerCaseForDataTestId } from '../../utils/utils';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZRadioConfig } from './types/ptz-radio.enums';
import { PTZRadioTypes } from './types/ptz-radio.types';

@Component({
  tag: 'ptz-radio',
  styleUrl: 'ptz-radio.scss',
  shadow: false,
})
export class PTZRadio {
  /** Cor onde o Radio Button é aplicado */
  @Prop() appearance?: PTZRadioTypes.Appearance = PTZRadioConfig.Appearance.Light;

  /** Define se vai utilizar estilos da Petz ou de outra marca */
  @Prop() brand?: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz;

  /** Atributo checked padrão do Radio Button  */
  @Prop({ mutable: true }) checked: boolean = false;

  /** Preenche id padrão do Radio Button */
  @Prop() inputId?: string;

  /** Atributo name padrão do Radio Button */
  @Prop() name: string;

  /** Tamanho do Radio Button */
  @Prop() size?: PTZRadioTypes.Size = PTZRadioConfig.Size.Md;

  /** Estado do Radio Button */
  @Prop() state?: PTZRadioTypes.State = PTZRadioConfig.State.Default;

  /** Atributo value padrão do Radio Button */
  @Prop() value: string;

  /** Estado de renderização */
  @Prop() skeleton: boolean = false;

  /** Evento para saber se o Radio button interno está checked */
  @Event({
    eventName: 'changeRadio',
    composed: true,
    cancelable: true,
    bubbles: true,
  })
  changeRadio: EventEmitter<PTZRadioTypes.ChangedEvent>;

  private _syncCheckedWith(value: boolean) {
    this.checked = value;
  }

  @Listen('change')
  _emitCheckedEvent(event) {
    if (this.state === PTZRadioConfig.State.Disabled) {
      return;
    }
    this._syncCheckedWith(event.target.checked);
    const value = this.checked;
    this.changeRadio.emit({ checked: value, value: this.value, name: this.name });
  }

  render() {
    const skeletonSize = {
      sm: 20,
      md: 24,
      lg: 32,
    };

    return (
      <Host>
        {this.skeleton ? (
          <ptz-skeleton width={skeletonSize[this.size]} height={skeletonSize[this.size]} rounded="max"></ptz-skeleton>
        ) : (
          <input
            data-testid={`${this.brand}-${this.value ? valueToLowerCaseForDataTestId(this.value) : valueToLowerCaseForDataTestId(this.inputId)}-radio`}
            type="radio"
            role="radio"
            aria-checked={this.checked}
            class={`
            ${this.brand}-radio
            ${this.size ? `${this.brand}-radio-${this.size}` : ''}
            ${this.brand}-radio-${this.appearance}${this.state && this.state !== PTZRadioConfig.State.Default ? `-${this.state}` : ''}`}
            name={this.name}
            disabled={this.state === PTZRadioConfig.State.Disabled}
            checked={this.checked}
            id={this.inputId}
            value={this.value}
          />
        )}
      </Host>
    );
  }
}
