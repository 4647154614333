import { EnumToArray } from '../../../utils/enumToArray';
import { PTZBrandConfig, PTZBrandConfigList } from '../../ptz-brand/types/ptz-brand.enums';

enum Appearance {
  Light = 'light',
  Dark = 'dark',
  Color = 'color',
}

enum Size {
  Lg = 'lg',
  Md = 'md',
  Sm = 'sm',
}

enum State {
  Error = 'error',
  Disabled = 'disabled',
  Default = 'default',
}

export const PTZRadioConfigList = {
  Appearance: EnumToArray([Appearance]),
  Brand: PTZBrandConfigList.Name,
  Size: EnumToArray([Size]),
  State: EnumToArray([State]),
};

export const PTZRadioConfig = {
  Appearance,
  Brand: PTZBrandConfig.Name,
  Size,
  State,
};
